<template>
  <div class="cases_box main">
    <div class="container">
      <div class="detail_header">
        <div class="title">Topics</div>
        <div class="year_box">
          Year:
          <el-select
            v-model="year"
            placeholder="Select"
            @change="changeYear"
            clearable
          >
            <el-option value="2021" label="2021"></el-option>
            <el-option value="2020" label="2020"></el-option>
            <el-option value="2019" label="2019"></el-option>
            <el-option value="2018" label="2018"></el-option>
            <el-option value="2017" label="2017"></el-option>
            <el-option value="2016" label="2016"></el-option>
          </el-select>
        </div>
      </div>
      <div class="enrty_list">
        <div
          class="item"
          v-for="(item, index) in list"
          :key="index"
          @click="toDetail(item.id)"
        >
          <img v-if="item.titleimg != ''" :src="$OSSURL + item.titleimg" />
          <div class="name">
            {{ item.title }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DATA } from '@/data/f_topics.js';
export default {
  name: 'cases',
  components: {},
  data() {
    return {
      year: '',
      list: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    changeYear() {
      this.getList();
    },
    getList() {
      console.log(this.year);
      this.list = [];
      if (this.year) {
        DATA.forEach(item => {
          if (item.year == this.year) {
            this.list.push(item);
          }
        });
      } else {
        this.list = DATA;
      }
    },
    toDetail(id) {
      this.$router.push({
        path: '/Festival/TopicsDetail',
        query: { id },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/cases.scss';
</style>
